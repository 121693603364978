import { ReactEditor } from 'slate-react';
import { Dispatch, KeyboardEventHandler, SetStateAction } from 'react';
import { useSlateHotkeys } from './useSlateHotkeys';
import { useSlateEnterKey } from './useSlateEnterKey';
import { FontSizeType, FontType } from './textEditor.type';

interface Params {
  editor: ReactEditor;
  fontSize: FontSizeType;
  setFontSize: Dispatch<SetStateAction<FontSizeType>>;
  fontType: FontType;
}

export const useSlateKeydown = ({ editor, fontSize, setFontSize, fontType }: Params) => {
  const handleSlateHotkeys = useSlateHotkeys({ editor, fontSize, setFontSize });
  const handleSlateEnterKey = useSlateEnterKey({ editor, fontSize, fontType });

  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (e.key !== 'Enter') {
      handleSlateHotkeys(e);
    } else {
      handleSlateEnterKey(e);
    }
  };

  return handleKeyDown;
};

import { useRef } from 'react';
import { Saturation } from './common/saturation/Saturation';
import classNames from 'classnames/bind';
import style from './ColorPicker.module.scss';
import { IColorModel, IColorPickerBaseProps, IRgbaColor } from './colorPicker.type';
import { hsvaToHex, hsvaToRgba, rgbaToHex, rgbaToHsva } from './utils/convert';
import { equalColorObjects } from './utils';
import { useColorManipulation } from './hooks/useColorManipulation';
import { Hue } from './common/hue/Hue';
import { Alpha } from './common/alpha/Alpha';
import ColorInput from './colorInput/ColorInput';
import Palette from './palette/Palette';
import { ON_OFF } from '../../constants/common';
import Checkbox from '../checkbox/Checkbox';
import { BRAND_COLOR_TYPE } from './brandColor/brandColor.type';
import Icon from '../icon/Icon';
import { COLORS } from '../../constants/colors';

const cx = classNames.bind(style);

const colorModel: IColorModel<IRgbaColor> = {
  defaultColor: { r: 0, g: 0, b: 0, a: 1 },
  toHsva: rgbaToHsva,
  fromHsva: hsvaToRgba,
  equal: equalColorObjects,
};

const ColorPicker = ({ color, onChange, brandColorOptions, ...rest }: IColorPickerBaseProps<IRgbaColor>) => {
  const nodeRef = useRef<HTMLDivElement>(null);

  const { hsva, handleChangeHsva, paletteColors } = useColorManipulation(colorModel, color, onChange);
  const checkboxRef = useRef<HTMLInputElement>(null);

  const renderBrandColorController = () => {
    if (brandColorOptions?.type === BRAND_COLOR_TYPE.CHECKBOX) {
      return (
        <div className={cx('brand-color-wrapper')}>
          <Checkbox
            ref={checkboxRef}
            checked={brandColorOptions.checked === ON_OFF.ON}
            onChange={brandColorOptions.onChange}
          />
          <label className={cx('check-label')} onClick={() => checkboxRef.current?.click()}>
            브랜드 컬러
          </label>
        </div>
      );
    } else if (brandColorOptions?.type === BRAND_COLOR_TYPE.BUTTON) {
      return (
        <div className={cx('brand-color-wrapper')}>
          <button className={cx('brand-color')} onClick={brandColorOptions.onClick}>
            <Icon name={'check_line'} size={18} color={COLORS.green600} />
            브랜드 컬러
          </button>
        </div>
      );
    }
  };

  return (
    <div {...rest} ref={nodeRef} className={cx('area', { isContent: rest.isContent })}>
      <Saturation hsva={hsva} onChange={handleChangeHsva} />

      <div className={cx('controlArea')}>
        <div className={cx('controlWrap')}>
          <Hue hue={hsva.h} onChange={handleChangeHsva} />
          <Alpha hsva={hsva} onChange={handleChangeHsva} />
        </div>

        <div
          className={cx('colorInputWrap', {
            'brand-color': brandColorOptions && Object.keys(brandColorOptions).length > 0,
          })}
        >
          <ColorInput color={color} onChange={onChange} />
        </div>

        {renderBrandColorController()}

        <Palette
          color={color}
          onChange={onChange}
          brandColorOptions={brandColorOptions}
          paletteColors={paletteColors}
        />
      </div>
    </div>
  );
};

export default ColorPicker;

import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import style from './ColorInput.module.scss';
import { hexRegex } from 'ui/util/reg';
import { IColorPickerBaseProps, IRgbaColor } from '../colorPicker.type';
import { alphaToPer, aToHex, hexToRgba, rgbToHex } from '../utils/convert';
import useInputHandle from 'ats-editor/src/hook/useInputHandle';
const cx = classNames.bind(style);

const ColorInput = ({ color, onChange, disabled = false }: IColorPickerBaseProps<IRgbaColor>) => {
  const [isOpacityInputFocused, setIsOpacityInputFocused] = useState(false);
  const {
    inputText: alphaText,
    setInputText: setAlphaText,
    handleChange: handleChangeAlpha,
    handleKeydown: handleKeydownAlpha,
    handleBlur: handleBlurAlpha,
    handleFocus: handleFocusAlpha,
  } = useInputHandle({
    initText: alphaToPer(color.a).toString(),
    value: `${(color.a * 100).toFixed(0)}${isOpacityInputFocused ? '' : '%'}`,
    onChange: (e) => {
      setIsOpacityInputFocused(true);
      const inputValue = e.target.value;
      const result = inputValue.replace(/[^0-9]+/g, '').slice(0, 3);
      const number = Number(result) > 100 ? 100 : Number(result);
      onChange(hexToRgba(rgbToHex(color) + aToHex(number / 100)));
      setAlphaText(String(number));
    },
    onBlur: () => {
      setIsOpacityInputFocused(false);
    },
    onFocus: () => {
      setIsOpacityInputFocused(true);
    },
  });

  const {
    inputText: colorText,
    setInputText: setColorText,
    handleKeydown: handleKeydownColor,
    handleChange: handleChangeColor,
    handleBlur: handleBlurColor,
  } = useInputHandle({
    initText: rgbToHex(color),
    value: rgbToHex(color),
    onBlur: () => {
      // 입력 받은 결과에서 숫자, a-f 를 제외한 문자를 제거 후, 앞에서 부터 6자리를 자릅니다.
      const result = colorText.replace(/[^a-fA-F0-9]+/g, '').slice(0, 6);

      // 앞에서 자른 6자리의 문자열이 rgb Hex 형식인지 테스트합니다.
      const isHexText = hexRegex(result);

      // 결과가 hex 형식인 경우 color와 colorText를 변경합니다.
      if (isHexText) {
        setColorText(result);
        if (color.a === 0) {
          color.a = 1;
        }
        onChange(hexToRgba(result + aToHex(color.a)));
        setColorText(rgbToHex(color));
      }
      // 결과가 hex 형식이 아닌 경우 원래의 color로 되돌아갑니다.
      else {
        setColorText(rgbToHex(color));
      }
    },
    onChange: (e) => {
      if (hexRegex(e.target.value)) {
        onChange && onChange(hexToRgba(e.target.value + aToHex(color.a)));
      }
      setColorText(e.target.value);
    },
  });

  return (
    <div className={cx('inputWrap')}>
      <input
        className={cx('input', { disabled: disabled })}
        type={'text'}
        value={colorText}
        onChange={handleChangeColor}
        onBlur={handleBlurColor}
        onKeyDown={handleKeydownColor}
      />
      <input
        className={cx('input', 'alpha', { disabled: disabled })}
        type={'text'}
        value={alphaText}
        onBlur={handleBlurAlpha}
        onFocus={handleFocusAlpha}
        onChange={handleChangeAlpha}
        onKeyDown={handleKeydownAlpha}
      />
    </div>
  );
};

export default ColorInput;

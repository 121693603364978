import classnames from 'classnames/bind';
import { FC, forwardRef, useEffect } from 'react';
import { useSlate } from 'slate-react';
import type { IElementAlign, SolidToolbar } from '../textEditor.type';
import { ISlateSolidToolbarProps, ISlateToolbarProps, SlateToolbarType } from '../textEditor.type';
import { getBlockMark } from './Slate.toolbar.util';
import style from './SlateToolbar.module.scss';
import CustomButtonToolbar from './component/CustomButtonToolbar';
import SlateDefaultToolbar from './component/SlateDefaultToolbar';

const cx = classnames.bind(style);

const SlateSolidToolbar: FC<ISlateToolbarProps & IElementAlign & ISlateSolidToolbarProps> = forwardRef(
  function SlateToolbar(
    {
      toolbarClassName,
      fontSize,
      setFontSize,
      fontType,
      setFontType,
      toolbarOption = { type: 'solid', solid: {} },
      backgroundColor,
      onChangeBgColor,
      pageList,
      fontList,
      defaultFont,
      brandColor,
    },
    ref
  ) {
    const editor = useSlate();

    /* Editor Cursor fontSize */
    //TODO : fontsize, type 정리하기
    useEffect(() => {
      setFontSize(getBlockMark(editor, 'fontSize') ?? fontSize);
    }, [editor.selection]);

    if (!ref) return null;

    const { solid } = toolbarOption as SlateToolbarType<SolidToolbar>;
    const { button } = solid ?? { button: false };

    return (
      <div
        id={'slate-toolbar-container'}
        className={cx('container', toolbarClassName && toolbarClassName)}
        style={{
          position: 'static',
          left: 0,
          top: 0,
        }}
      >
        <div className={cx('default-container', 'solid')}>
          <SlateDefaultToolbar
            showToolbarImageIcon={solid.showImageIcon ?? true}
            ref={ref}
            fontSize={fontSize}
            setFontSize={setFontSize}
            fontType={fontType}
            setFontType={setFontType}
            pageList={pageList}
            fontList={fontList}
            defaultFont={defaultFont}
            brandColor={brandColor}
          />
        </div>
        {button && (
          <div className={cx('customToolbar')}>
            <CustomButtonToolbar backgroundColor={backgroundColor!} onChangeBgColor={onChangeBgColor} />
          </div>
        )}
      </div>
    );
  }
);

export default SlateSolidToolbar;

import React, { FC, MouseEventHandler } from 'react';

import BoldIcon from 'ui/common/assets/editor_icons/icon_bold_48_line.svg';
import UnderlineIcon from 'ui/common/assets/editor_icons/icon_underlined_u_48_line.svg';
import NumberedListIcon from 'ui/common/assets/editor_icons/icon_list_numbered_48_line.svg';
import BulletedListIcon from 'ui/common/assets/editor_icons/icon_list_48_line.svg';
import HighlightIcon from 'ui/common/assets/editor_icons/icon_highlighter_48_filled.svg';
import InsertLinkIcon from 'ui/common/assets/editor_icons/icon_link_48_line.svg';
import TextColorIcon from 'ui/common/assets/editor_icons/icon_text color_24.svg';
import Icon from '../icon/Icon';
import type { BlockFormatType } from './textEditor.type';
import { MarkFormatType } from './textEditor.constant';
import { COLORS } from '../../constants/colors';

interface IProps {
  name: BlockFormatType | MarkFormatType;
  size?: 18 | 24 | 32 | 48;
  color?: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLElement>;
}

const EditorIcon: FC<IProps> = (props) => {
  const { name, size = 24, color = COLORS.gray800, className, onClick } = props;
  const iconProps = {
    width: size,
    height: size,
    fill: color,
    className,
    onClick,
  };

  switch (name) {
    case 'bold':
      return <BoldIcon {...iconProps} />;
    case 'underline':
      return <UnderlineIcon {...iconProps} />;
    case 'numbered-list':
      return <NumberedListIcon {...iconProps} />;
    case 'bulleted-list':
      return <BulletedListIcon {...iconProps} />;
    case 'highlight':
      return <HighlightIcon {...iconProps} />;
    case 'link':
      return <InsertLinkIcon {...iconProps} />;
    case 'italic':
      return <Icon name={'italic_line'} size={24} color={color} />;
    case 'left':
      return <Icon name={'align_left_line'} size={24} color={color} />;
    case 'center':
      return <Icon name={'align_center_line'} size={24} color={color} />;
    case 'right':
      return <Icon name={'align_right_line'} size={24} color={color} />;
    case 'strikethrough':
      return <Icon name={'strikethrough_s_line'} size={24} color={color} />;
    case 'color':
      return <TextColorIcon {...iconProps} />;
    default:
      return <></>;
  }
};

export default EditorIcon;

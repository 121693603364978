import classnames from 'classnames/bind';
import { FC, useEffect, useRef, useState } from 'react';
import { BaseEditor, Editor } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import { COLORS } from '../../../../constants/colors';
import type { IFont } from '../../../../constants/font.constant';
import useOnClickOutside from '../../../../hook/useOnClickOutside';
import Icon from '../../../icon/Icon';
import type { ShowPopType } from '../../textEditor.constant';
import style from './ToolbarButton.module.scss';

const cx = classnames.bind(style);

const getFontFamily = (editor: BaseEditor & ReactEditor, format: 'fontFamily'): string | null => {
  const marks = Editor.marks(editor);

  return marks ? (marks[format] as string) : null;
};

const toggleFontFamily = (editor: BaseEditor & ReactEditor, format: 'fontFamily', fontType: IFont['fontFamily']) => {
  Editor.addMark(editor, format, fontType);
};

interface IProps {
  setShowPop: (v: ShowPopType) => void;
  showPop: ShowPopType;
  fontList: IFont[];
  defaultFont: IFont;
}

const FontFamilyButton: FC<IProps> = ({ showPop, setShowPop, fontList, defaultFont }) => {
  const editor = useSlate();
  const fontFamilyRef = useRef<HTMLButtonElement>(null);
  const [fontFamily, setFontFamily] = useState(defaultFont.fontFamily);
  const font = fontList.find((font) => font.fontFamily === fontFamily) ?? defaultFont;

  useOnClickOutside(fontFamilyRef, () => {
    showPop === 'FONT_FAMILY' && setShowPop(null);
  });

  /* Editor Cursor fontSize */
  useEffect(() => {
    setFontFamily(getFontFamily(editor, 'fontFamily') ?? 'Pretendard');
  }, [editor.selection]);

  return (
    <button
      className={cx('container', 'select-font')}
      onMouseDown={(e) => e.preventDefault()}
      onClick={() => {
        setShowPop(showPop === 'FONT_FAMILY' ? null : 'FONT_FAMILY');
      }}
      type={'button'}
      ref={fontFamilyRef}
    >
      {showPop === 'FONT_FAMILY' && (
        <div className={cx('popover-wrapper', 'font-family')}>
          <ul className={cx('popover-container')}>
            {fontList.map((font) => (
              <li
                className={cx({ active: fontFamily === font.fontFamily })}
                key={font.fontFamily}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  setFontFamily(font.fontFamily);
                  toggleFontFamily(editor, 'fontFamily', font.fontFamily);
                  setShowPop(null);
                }}
              >
                <span>{font.name}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
      <span>{font.name}</span>
      <Icon name={'arrow_drop_down_filled'} size={24} color={COLORS.gray600} className={cx('icon')} />
    </button>
  );
};

export default FontFamilyButton;

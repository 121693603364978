import React, { FC, useEffect, useState } from 'react';
import { Element as SlateElement, Editor, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import classnames from 'classnames/bind';
import style from './CustomButtonSettingToolbar.module.scss';
import RadioStyle from 'ats-editor/src/component/editor/setting/editorRNB/common/component/RadioStyle';
import { isHttpReg } from '../../../../util/reg';
import { hexToRgba, rgbaToHex } from '../../../colorPicker/utils/convert';
import { Align } from '../../../../interface/common/Align';
import useSettingPopupTextButton from './useSettingPopupTextButton';
import Icon from '../../../icon/Icon';
import TextFieldBase from '../../../textfield/TextFieldBase';
import Paragraph from '../../../paragraph/Paragraph';
import { CustomElement } from '../../textEditor.type';
import Dropdown from '../../../dropdown/Dropdown';
import Button from '../../../button/Button';
import ColorPickerPopover from 'ats-editor/src/component/editor/setting/common/colorPickerPopover/ColorPickerPopover';

const cx = classnames.bind(style);

interface IProps {
  setActiveButtonSetting: (active: boolean) => void;
}

// @ts-ignore
const isActive = (editor: Editor) => {
  if (editor.selection) {
    const [match] = Editor.nodes(editor, {
      at: Editor.unhangRange(editor, editor.selection),
      // @ts-ignore
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'button',
    });

    return match;
  }

  return;
};

// @ts-ignore
const CustomButtonSettingToolbar: FC<IProps> = ({ setActiveButtonSetting }) => {
  const editor = useSlate();
  const { handleButtonStyle, handleButtonValue } = useSettingPopupTextButton();
  const [showButtonColorPicker, setShowButtonColorPicker] = useState(false);
  const [showTextColorPicker, setShowTextColorPicker] = useState(false);
  const [isInvalidUrl, setIsInvalidUrl] = useState(false);

  const { selection } = editor;

  useEffect(() => {
    setActiveButtonSetting(!!isActive(editor));
  }, [editor.selection]);

  useEffect(() => {
    const deselectOnClickOutside = (e: MouseEvent) => {
      if (!isActive(editor)) return;
      if (e.target && (e.target as HTMLElement).closest('.custom-button-toolbar')) return;
      if (e.target && (e.target as HTMLElement).closest('[data-id="slate-editor"]')) return;

      Transforms.deselect(editor);
    };

    document.addEventListener('click', deselectOnClickOutside);
    return () => document.removeEventListener('click', deselectOnClickOutside);
  });

  if (!selection) return null;

  const match = isActive(editor);

  if (!match) return null;
  const [{ style, size, align, pageLink, url: deprecatedUrl }] = match as CustomElement[];
  const url = pageLink?.lnb?.page?.url ?? deprecatedUrl;
  const { backgroundColor, color, borderRadius } = style!;

  const handleBlurUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isNotIncludeProtocol = !isHttpReg(url!);
    if (url && isNotIncludeProtocol)
      handleButtonValue(editor, match[0] as CustomElement, {
        pageLink: {
          lnb: {
            page: {
              url: `https://${url}`,
            },
          },
        },
      });

    if (!isHttpReg((isNotIncludeProtocol ? 'https://' : '') + url!)) setIsInvalidUrl(true);
    else setIsInvalidUrl(false);
  };

  const handleRemoveButton = () => {
    ReactEditor.focus(editor);
    Transforms.removeNodes(editor);
  };

  return (
    <div className={cx('container', 'custom-button-toolbar')}>
      <div className={cx('group')}>
        <div className={cx('block')}>
          <div className={cx('wrapper')}>
            <Paragraph className={cx('label')} variant={'B4'} bold>
              버튼 크기
            </Paragraph>
            <Dropdown
              className={cx('size-picker')}
              size={'sm'}
              options={['S', 'M', 'L'].map((size) => ({ name: size, value: size }))}
              onChange={(newValue) => handleButtonValue(editor, match[0] as CustomElement, { size: newValue })}
              value={size}
              defaultValue={size}
            />
          </div>
          <div className={cx('wrapper')} onClick={() => setShowButtonColorPicker(true)}>
            <Paragraph className={cx('label')} variant={'B4'} bold>
              버튼 색상
            </Paragraph>
            <div
              className={cx('color-picker')}
              style={{
                backgroundColor: `${backgroundColor}`,
              }}
            />
            <TextFieldBase className={cx('color-input')} size={'sm'} value={backgroundColor} readOnly />
            <ColorPickerPopover
              isVisible={showButtonColorPicker}
              setIsVisible={setShowButtonColorPicker}
              color={hexToRgba(style?.backgroundColor ?? '#000000')}
              onChange={(color) => {
                handleButtonStyle(editor, match[0] as CustomElement, { backgroundColor: rgbaToHex(color) });
              }}
            />
          </div>
          <div className={cx('wrapper')} onClick={() => setShowTextColorPicker(true)}>
            <Paragraph className={cx('label')} variant={'B4'} bold>
              텍스트 색상
            </Paragraph>
            <div
              className={cx('color-picker')}
              style={{
                backgroundColor: `${color}`,
              }}
            />
            <TextFieldBase className={cx('color-input')} size={'sm'} value={color} readOnly />
            <ColorPickerPopover
              isVisible={showTextColorPicker}
              setIsVisible={setShowTextColorPicker}
              color={hexToRgba(style?.color ?? '#000000')}
              onChange={(color) => {
                handleButtonStyle(editor, match[0] as CustomElement, { color: rgbaToHex(color) });
              }}
            />
          </div>
          <div className={cx('wrapper')}>
            <Paragraph className={cx('label')} variant={'B4'} bold>
              라운딩
            </Paragraph>
            <TextFieldBase
              className={cx('border-input')}
              size={'sm'}
              type={'number'}
              min={0}
              max={100}
              value={borderRadius}
              textAlign={'right'}
              onChange={(e) => {
                handleButtonStyle(editor, match[0] as CustomElement, {
                  borderRadius:
                    Number(e.target.value) >= 100 ? 100 : Number(e.target.value) < 0 ? 0 : Number(e.target.value),
                });
              }}
            />
          </div>
        </div>

        <Button size={'sm'} variant={'secondary'} color={'error'} onClick={handleRemoveButton}>
          버튼삭제
        </Button>
      </div>
      <div className={cx('block')}>
        <div className={cx('wrapper')}>
          <div className={cx('align')}>
            {/*@ts-ignore*/}
            <RadioStyle
              list={[
                { render: <Icon name={'hori_align_left'} size={24} />, value: Align.LEFT },
                { render: <Icon name={'hori_align_center'} size={24} />, value: Align.CENTER },
                { render: <Icon className={cx('rotate')} name={'hori_align_left'} size={24} />, value: Align.RIGHT },
              ]}
              value={align ?? Align.LEFT}
              onChange={(e) => {
                handleButtonValue(editor, match[0] as CustomElement, { align: e.target.value });
              }}
              name={'contents-size'}
            />
          </div>
        </div>
        <div className={cx('wrapper')}>
          <Paragraph className={cx('label')} variant={'B4'} bold>
            링크
          </Paragraph>

          <TextFieldBase
            error={isInvalidUrl}
            className={cx('link')}
            size={'sm'}
            value={url}
            placeholder={'https://'}
            onBlur={handleBlurUrl}
            onChange={(e) => {
              handleButtonValue(editor, match[0] as CustomElement, {
                pageLink: {
                  lnb: {
                    page: {
                      url: e.target.value,
                    },
                  },
                },
              });
            }}
          />
          {isInvalidUrl && <div className={cx('error')}>올바른 url 형식이 아닙니다.</div>}
        </div>
      </div>
    </div>
  );
};

export default CustomButtonSettingToolbar;

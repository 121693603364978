import { ReactEditor } from 'slate-react';
import { Dispatch, KeyboardEventHandler, SetStateAction } from 'react';
import { Editor } from 'slate';
import { DEFAULT_FONT_SIZE, MARK_FORMAT, MarkFormatType } from './textEditor.constant';
import { FontSizeType } from './textEditor.type';

interface Params {
  editor: ReactEditor;
  fontSize: number;
  setFontSize: Dispatch<SetStateAction<FontSizeType>>;
}
export const useSlateHotkeys = ({ editor, fontSize, setFontSize }: Params) => {
  const isMarkActive = (mark: MarkFormatType) => {
    const marks = Editor.marks(editor);
    return marks ? marks[mark] === true : false;
  };

  const toggleMark = (mark: MarkFormatType) => {
    const isActive = isMarkActive(mark);
    if (isActive) Editor.removeMark(editor, mark);
    else Editor.addMark(editor, mark, true);
  };

  const changeFontSize = (fontSize: FontSizeType = DEFAULT_FONT_SIZE) => {
    setFontSize(fontSize);
    Editor.addMark(editor, 'fontSize', fontSize);
  };

  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (!e.ctrlKey && !e.metaKey) return;

    const key = e.key;

    // bold
    if (key === 'b') {
      toggleMark(MARK_FORMAT.BOLD);
      return;
    }

    // italic
    if (key === 'i') {
      toggleMark(MARK_FORMAT.ITALIC);
      return;
    }

    // underline
    if (key === 'u') {
      toggleMark(MARK_FORMAT.UNDERLINE);
      return;
    }

    // increase font size
    if (e.shiftKey && key === '.') {
      changeFontSize((fontSize + 1) as FontSizeType);
      return;
    }

    // decrease font size
    if (e.shiftKey && key === ',') {
      changeFontSize((fontSize - 1) as FontSizeType);
      return;
    }
  };

  return handleKeyDown;
};

import _axios from 'axios';
import Cookies from 'js-cookie';
import { postTokenReIssueV1 } from '../..//auth';
import CustomAxiosError, { ErrorCode } from '../../interface/error/CustomAxiosError';
import { getBaseURL } from './common';

const PRODUCTION_LOGIN_URL = 'https://h.place/login';
const STAGE_LOGIN_URL = 'https://hdot.kr-st-jainwon.com/login';
const STAGE2_LOGIN_URL = 'https://hdot.kr-st2-jainwon.com/login';
const DEVELOPMENT_LOGIN_URL = 'https://hdot.kr-dv-jainwon.com/login';

export const getLoginURL = () => {
  // eslint-disable-next-line turbo/no-undeclared-env-vars
  const env = process?.env?.NEXT_PUBLIC_ENV ?? 'development';

  switch (env) {
    case 'development':
      return DEVELOPMENT_LOGIN_URL;
    case 'staging':
      return STAGE_LOGIN_URL;
    case 'staging2':
      return STAGE2_LOGIN_URL;
    case 'production':
      return PRODUCTION_LOGIN_URL;
    default:
      return DEVELOPMENT_LOGIN_URL;
  }
};

export const generateAxios = () => {
  const axios = _axios.create({ baseURL: getBaseURL() });
  axios.defaults.withCredentials = true;

  /** 모든 요청 때마다 header에 cookie에 있는 accessToken 담기 */
  axios.interceptors.request.use(function (config) {
    const accessToken = Cookies.get('access_token');
    if (!accessToken) return config;

    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  });

  /** 요청의 응답이 인증 에러일 경우(만료시) accessToken 재발급, 원래 하려던 요청 새로 발급받은 accessToken으로 재요청 */
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      if (!error.response) {
        return Promise.reject(
          new CustomAxiosError({ code: ErrorCode.TimeoutException, message: 'Network Error', error: {} })
        );
      }

      const {
        response: { data },
        config,
      } = error;

      if (data.code === 'ExpiredJwtException') {
        const { accessToken } = await postTokenReIssueV1();
        Cookies.set('access_token', accessToken, { expires: 365 });
        return axios(config);
      }

      return Promise.reject(new CustomAxiosError(data));
    }
  );

  return axios;
};

const axios = generateAxios();
const editorAxios = generateAxios();

// 사용되지 않음
// export const setAxiosHeader = (key: string, value: string) => {
//   axios.defaults.headers.common[key] = value;
// };

export { axios, editorAxios };

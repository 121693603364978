export const ErrorCode = {
  MainMenuVisibleException: 'MainMenuVisibleException',
  NotVisibleMenuException: 'NotVisibleMenuException',
  ForbiddenInternalUrlException: 'ForbiddenInternalUrlException',
  ExistsInternalUrlException: 'ExistsInternalUrlException',
  ArticleCategoryMaxLimitException: 'ArticleCategoryMaxLimitException',
  ExistsCategoryNameException: 'ExistsCategoryNameException',
  NotFoundArticleCategoryException: 'NotFoundArticleCategoryException',
  TimeoutException: 'TimeoutException',
} as const;

class CustomAxiosError extends Error {
  code: keyof typeof ErrorCode;
  message: string;
  error: any;

  constructor({ code, message, error }: { code: keyof typeof ErrorCode; message: string; error: any }) {
    super();
    this.code = code;
    this.message = message;
    this.error = error;
  }
}

export default CustomAxiosError;

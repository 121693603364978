import { Nullable } from 'ui/interface/common/common';
import { FC, useEffect, useRef, useState } from 'react';
import { HIDE_TYPE, IFolderLNBType, ILNBType, ILNBTypes, ISingleLNBType } from 'ui/interface/header/IMenu';

import classnames from 'classnames/bind';
import style from './usePageDropdown.module.scss';
import Icon from '../../common/icon/Icon';
import { COLORS } from '../../constants/colors';
import useOnClickOutside from '../useOnClickOutside';

const cx = classnames.bind(style);

interface IProps {
  pageList: ILNBType<ILNBTypes>[];
  initialPage?: Nullable<ILNBType<ISingleLNBType>>;
}

interface IDropdownProps {
  dropdownPosition?: 'top' | 'bottom';
}

const usePageDropdown = ({ pageList, initialPage }: IProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [tempPage, setTempPage] = useState<Nullable<ILNBType<ISingleLNBType>>>(null);

  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropdownRef, () => {
    setShowDropdown(false);
  });

  useEffect(() => {
    setTempPage(initialPage);
  }, []);

  const LNBReduce = pageList
    .reduce(
      (acc, cur) =>
        cur.formType === 'FOLDER'
          ? [...acc, ...(cur as ILNBType<IFolderLNBType>).subMenuList]
          : [...acc, cur as ILNBType<ISingleLNBType>],
      [] as ILNBType<ISingleLNBType>[]
    )
    .filter(({ hideType }) => hideType !== HIDE_TYPE.FULL);

  const PageDropdown: FC<IDropdownProps> = ({ dropdownPosition = 'bottom' }) => {
    return (
      <div
        className={cx('dropdown', { active: showDropdown }, { placeholder: !tempPage?.page?.pageSn })}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <>
          {tempPage?.page?.pageSn
            ? LNBReduce.find(({ page }) => page.pageSn === tempPage?.page?.pageSn)?.title
            : '선택해주세요'}
        </>
        {showDropdown && (
          <div
            ref={dropdownRef}
            className={cx('dropdown-wrapper')}
            style={dropdownPosition === 'bottom' ? { top: '45px' } : { bottom: '45px' }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={cx('dropdown-list')}>
              {LNBReduce.map((lnb) => (
                <div
                  className={cx('page', { active: lnb.menuSn === tempPage?.menuSn })}
                  key={lnb.title + lnb.menuSn}
                  onClick={(e) => {
                    e.stopPropagation();
                    setTempPage(lnb);
                    setShowDropdown(false);
                  }}
                >
                  {lnb.title}
                </div>
              ))}
            </div>
          </div>
        )}
        <Icon name={'arrow_bottom_line'} size={24} color={showDropdown ? COLORS.gray800 : COLORS.gray600} />
      </div>
    );
  };

  return { PageDropdown, setShowDropdown, tempPage, setTempPage, LNBReduce };
};

export default usePageDropdown;

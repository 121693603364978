import classnames from 'classnames/bind';
import React, { FC, forwardRef } from 'react';
import Portal from '../../portal/Portal';
import type { FloatToolbar, IElementAlign, SlateToolbarType } from '../textEditor.type';
import { ISlateToolbarProps } from '../textEditor.type';
import useToolbarAbsolutePosition from '../useToolbarAbsolutePosition';
import SlateDefaultToolbar from './component/SlateDefaultToolbar';
import style from './SlateToolbar.module.scss';

const cx = classnames.bind(style);

const SlateFloatToolbar: FC<ISlateToolbarProps & IElementAlign> = forwardRef(function SlateToolbar(
  {
    toolbarClassName,
    fontSize,
    setFontSize,
    fontType,
    setFontType,
    setPlaceHolderAlign,
    toolbarOption = { type: 'float', float: {} },
    pageList,
    fontList,
    defaultFont,
    brandColor,
  },
  ref
) {
  const { top, left } = useToolbarAbsolutePosition({
    ref: ref as React.RefObject<any>, // 콘텐츠 ref
    toolbarWidth: 720, //FIXME 툴바 width
  });

  const { float } = toolbarOption as SlateToolbarType<FloatToolbar>;

  return (
    <Portal domId={'slate-toolbar'}>
      <div
        id={'slate-toolbar-container'}
        className={cx('container', toolbarClassName && toolbarClassName)}
        style={{
          position: 'absolute',
          left,
          top,
        }}
      >
        <div className={cx('default-container', 'float')}>
          <SlateDefaultToolbar
            brandColor={brandColor}
            showToolbarImageIcon={float?.showImageIcon ?? false}
            ref={ref}
            fontSize={fontSize}
            setFontSize={setFontSize}
            fontType={fontType}
            setFontType={setFontType}
            setPlaceHolderAlign={setPlaceHolderAlign}
            pageList={pageList}
            fontList={fontList}
            defaultFont={defaultFont}
          />
        </div>
      </div>
    </Portal>
  );
});

export default SlateFloatToolbar;

import { useState, useEffect, useCallback, useRef } from 'react';
import { IColorModel, AnyColor, IHsvaColor, IRgbaColor } from '../colorPicker.type';
import { equalColorObjects } from '../utils';
import { useEventCallback } from './useEventCallback';
import { hsvaToHex, hsvaToRgba, rgbaToHex } from '../utils/convert';

const defaultColors = ['#FA5252', '#E64980', '#BE4BDB', '#7950F2', '#4C6EF5', '#228BE6', '#15AABF'];

export function useColorManipulation<T extends IRgbaColor>(
  colorModel: IColorModel<T>,
  color: T,
  onChange?: (color: T) => void
) {
  const onChangeCallback = useEventCallback<T>(onChange);
  const [hsva, updateHsva] = useState<IHsvaColor>(() => colorModel.toHsva(color));
  const cache = useRef({ color, hsva });

  const storedColors = localStorage.getItem('paletteColors');
  const paletteColors = storedColors ? JSON.parse(storedColors) : defaultColors;
  const clickedLastColor = useRef('');

  useEffect(() => {
    if (!colorModel.equal(color, cache.current.color)) {
      const newHsva = colorModel.toHsva(color);
      clickedLastColor.current = rgbaToHex(color as IRgbaColor);
      cache.current = { hsva: newHsva, color };
      updateHsva(newHsva);
    }
  }, [color, colorModel]);

  useEffect(() => {
    let newColor;
    if (
      !equalColorObjects(hsva, cache.current.hsva) &&
      !colorModel.equal((newColor = colorModel.fromHsva(hsva)), cache.current.color)
    ) {
      cache.current = { hsva, color: newColor };
      if (newColor.a === 0) {
        onChangeCallback({ ...newColor, a: 1 });
      } else {
        onChangeCallback(newColor);
      }
    }
  }, [hsva, colorModel, onChangeCallback]);

  const handleChangeHsva = useCallback(
    (params: Partial<IHsvaColor>) => {
      const newHsva = { ...hsva, ...params };
      clickedLastColor.current = hsvaToHex(newHsva);
      updateHsva(newHsva);
    },
    [hsva]
  );

  useEffect(() => {
    return () => {
      if (clickedLastColor.current) {
        // 이미 palette에 있는 색상이라면 palette에 마지막에 선택한 색상을 추가하지 않음.
        if (paletteColors.includes(clickedLastColor.current)) {
          return;
        }

        const updatedPalette = [
          clickedLastColor.current, // 마지막에 선택한 색상 (2행 첫번째)
          ...paletteColors.slice(0, paletteColors.length - 1), // 기존 색상 (2행 나머지)
        ];

        localStorage.setItem('paletteColors', JSON.stringify(updatedPalette));
      }
    };
  }, []);

  return { hsva, handleChangeHsva, paletteColors };
}

import { FC, forwardRef, useEffect } from 'react';
import { useSlate } from 'slate-react';
import { IElementAlign, ISlateSolidToolbarProps, ISlateToolbarProps } from '../textEditor.type';
import { getBlockMark } from './Slate.toolbar.util';
import SlateFloatToolbar from './SlateFloatToolbar';
import SlateSolidToolbar from './SlateSolidToolbar';

const SlateToolbar: FC<ISlateToolbarProps & IElementAlign & ISlateSolidToolbarProps> = forwardRef(function SlateToolbar(
  props,
  ref
) {
  const editor = useSlate();
  const { setFontSize, setFontType, toolbarOption } = props;

  useEffect(() => {
    const fontSizeOfSelection = getBlockMark(editor, 'fontSize');
    const fontTypeOfSelection = getBlockMark(editor, 'fontType');
    fontSizeOfSelection && setFontSize(fontSizeOfSelection);
    fontTypeOfSelection && setFontType(fontTypeOfSelection);
  }, [editor.selection]);

  if (!ref) return null;

  switch (toolbarOption!.type) {
    case 'solid':
      return <SlateSolidToolbar {...props} ref={ref} />;
    default:
      return <SlateFloatToolbar {...props} ref={ref} />;
  }
});

export default SlateToolbar;

import { axios } from '../api/requestClient/editor';

/** postTokenIssueV1 Request Interface */
export interface IPostJobdaTokenIssueRequest {
  authorizationCode: string;
  publicKey: string;
}

export interface IPostJobflexTokenIssueRequest {
  accessToken: string;
  companySn: string;
  loginExpireTime: string;
}

/** authorizationCode와 publicKey로 로그인 요청하는 API CALL METHOD */
export const postJobdaTokenIssueV1 = async ({ authorizationCode, publicKey }: IPostJobdaTokenIssueRequest) => {
  const { data } = await axios.post('/auth/v1/jobda/token/issue', { authorizationCode, publicKey });
  return data;
};

/** accessToken 재발급 */
export const postTokenReIssueV1 = async () => {
  const { data } = await axios.post('/auth/v1/token/re-issue');
  return data;
};

export const postJobflexTokenIssueV1 = async (args: IPostJobflexTokenIssueRequest) => {
  const { data } = await axios.post('/auth/v1/jobflex/token/issue', args);
  return data;
};

/** retention(llm) AccessToken 발급 */
export const postLlmTokenIssueV1 = async () => {
  const { data } = await axios.post('/auth/v1/llm/token/issue');
  return data;
};

import React, { useEffect, useState } from 'react';
import { hexRegex } from 'ui/util/reg';
import { aToHex, hexToRgba } from 'ui/common/colorPicker/utils/convert';
import { IColorPickerBaseProps, IRgbaColor } from 'ui/common/colorPicker/colorPicker.type';

interface IUseInputHandleProp {
  initText?: string;
  value: string;
  onBlur?: () => void;
  onFocus?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const useInputHandle = ({ initText = '', value, onBlur, onFocus, onChange }: IUseInputHandleProp) => {
  const [inputText, setInputText] = useState<string>(initText);

  useEffect(() => {
    setInputText(value);
  }, [value]);

  const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && handleBlur();

  const handleChangeInputText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
  };

  const handleBlur = () => onBlur?.();
  const handleFocus = () => onFocus?.();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange?.(e);

  return {
    inputText,
    setInputText,
    handleKeydown,
    handleChangeInputText,
    handleChange,
    handleBlur,
    handleFocus,
  };
};

export default useInputHandle;

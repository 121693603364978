import { ReactEditor } from 'slate-react';
import { KeyboardEventHandler } from 'react';
import { Editor, Element as SlateElement, Transforms, Path, Node } from 'slate';
import { CustomElement, FontSizeType, FontType } from './textEditor.type';
import { getInitialEditorText } from './textEditor.constant';

interface Params {
  editor: ReactEditor;
  fontSize: FontSizeType;
  fontType: FontType;
}

function isButtonElement(node: any): node is CustomElement {
  return SlateElement.isElement(node) && node.type === 'button';
}

export const useSlateEnterKey = ({ editor, fontType, fontSize }: Params) => {
  const handleKeyDown: KeyboardEventHandler = (e) => {
    const { selection } = editor;
    if (!selection) return;

    // 현재 Selection이 button 노드 안에 있는지 확인
    const buttonEntry = Editor.above(editor, {
      match: (n) => isButtonElement(n),
      at: selection.focus,
    });

    if (!buttonEntry) {
      return;
    }

    // Enter 기본 동작 방지
    e.preventDefault();

    const [buttonNode, buttonPath] = buttonEntry;

    // 1) 버튼의 '시작' 위치인지 판단
    const isAtStart = Editor.isStart(editor, selection.focus, buttonPath);

    // 새로 삽입할 문단 노드
    const newParagraph = {
      type: 'paragraph',
      children: [getInitialEditorText({ fontSize, fontType, text: '' })],
    } as Node;

    if (isAtStart) {
      // 버튼의 시작 위치라면 => 버튼 위에 새 문단 삽입
      Transforms.insertNodes(editor, newParagraph, { at: buttonPath });

      // 새로 삽입된 문단에 커서 이동
      Transforms.select(editor, Editor.start(editor, buttonPath));
    } else {
      // 버튼의 중간 or 끝 => 버튼 아래에 새 문단 삽입
      const nextPath = Path.next(buttonPath);
      Transforms.insertNodes(editor, newParagraph, { at: nextPath });

      // 새로 삽입된 문단에 커서 이동
      Transforms.select(editor, Editor.start(editor, nextPath));
    }
  };

  return handleKeyDown;
};

import { OnOffType } from '../../../constants/common';

export interface IBrandColorOptionsProps {
  type: BrandColorType;
  brandColor?: string;
  checked?: OnOffType;
  onChange?: (checked: boolean) => void;
  onClick?: () => void;
}

export const BRAND_COLOR_TYPE = {
  CHECKBOX: 'CHECKBOX',
  BUTTON: 'BUTTON',
} as const;
export type BrandColorType = keyof typeof BRAND_COLOR_TYPE;

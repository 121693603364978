import classnames from 'classnames/bind';
import { FC, useEffect, useRef, useState } from 'react';
import { Editor, Range } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import { IFont } from '../../../constants/font.constant';
import { ILNBType, ILNBTypes } from '../../../interface/header/IMenu';
import Portal from '../../portal/Portal';
import { MARK_FORMAT, ShowPopType } from '../textEditor.constant';
import { FontSizeType, FontType, IElementAlign } from '../textEditor.type';
import BlockButton from '../toolbar/component/BlockButton';
import ColorPickerButton from '../toolbar/component/ColorPickerButton';
import FontFamilyButton from '../toolbar/component/FontFamilyButton';
import FontSizeButton from '../toolbar/component/FontSizeButton';
import FontTypeButton from '../toolbar/component/FontTypeButton';
import HyperLinkButton from '../toolbar/component/HyperLinkButton';
import MarkButton from '../toolbar/component/MarkButton';
import style from './HoveringToolbar.module.scss';

const cx = classnames.bind(style);

interface IProps {
  fontSize: FontSizeType;
  setFontSize: (v: FontSizeType) => void;
  fontType: FontType;
  setFontType: (v: FontType) => void;
  pageList?: ILNBType<ILNBTypes>[];
  fontList: IFont[];
  defaultFont: IFont;
  brandColor?: string;
}

const HOVERING_OFFSET_WIDTH = 720;
const OVERFLOW_EDITOR_OFFSET = 1028;

const HoveringToolbar: FC<IElementAlign & IProps> = ({
  setPlaceHolderAlign,
  pageList,
  fontSize,
  setFontSize,
  fontType,
  setFontType,
  fontList,
  defaultFont,
  brandColor,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const editor = useSlate();

  const [showPop, setShowPop] = useState<ShowPopType>(null);

  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    if (
      !selection ||
      !ReactEditor.isFocused(editor) ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      if (showPop) return;
      el.removeAttribute('style');
      return;
    }

    const domSelection = window.getSelection();
    if (domSelection) {
      const domRange = domSelection.getRangeAt(0);
      const rect = domRange.getBoundingClientRect();
      el.style.display = 'flex';
      el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight - 9}px`;

      const EDITOR_OVER_WIDTH = rect.left + HOVERING_OFFSET_WIDTH - OVERFLOW_EDITOR_OFFSET;
      el.style.left = `${
        OVERFLOW_EDITOR_OFFSET < rect.left + HOVERING_OFFSET_WIDTH ? rect.left - EDITOR_OVER_WIDTH : rect.left
      }px`;
    }
  });

  return (
    <Portal domId={'slate-hovering-toolbar'}>
      <div className={cx('container')} ref={ref} onMouseDown={(e) => e.stopPropagation()}>
        <div className={cx('wrapper')}>
          <FontTypeButton
            fontSize={fontSize}
            setFontSize={setFontSize}
            fontType={fontType}
            setFontType={setFontType}
            showPop={showPop}
            setShowPop={setShowPop}
          />
        </div>
        <div className={cx('wrapper')}>
          <FontFamilyButton showPop={showPop} setShowPop={setShowPop} fontList={fontList} defaultFont={defaultFont} />
        </div>
        <div className={cx('divider')} />
        <div className={cx('wrapper')}>
          <FontSizeButton
            fontSize={fontSize}
            setFontSize={setFontSize}
            fontType={fontType}
            setFontType={setFontType}
            showPop={showPop}
            setShowPop={setShowPop}
          />
        </div>
        <div className={cx('divider')} />
        <div className={cx('wrapper')}>
          <BlockButton format={'left'} setPlaceHolderAlign={setPlaceHolderAlign} />
          <BlockButton format={'center'} setPlaceHolderAlign={setPlaceHolderAlign} />
          <BlockButton format={'right'} setPlaceHolderAlign={setPlaceHolderAlign} />
        </div>
        <div className={cx('divider')} />
        <div className={cx('wrapper')}>
          <MarkButton format={'bold'} />
          <MarkButton format={'italic'} />
          <MarkButton format={'underline'} />
          <MarkButton format={'strikethrough'} />
          <ColorPickerButton
            showPop={showPop}
            setShowPop={setShowPop}
            format={MARK_FORMAT.HIGHLIGHT}
            brandColor={brandColor}
          />
          <ColorPickerButton
            showPop={showPop}
            setShowPop={setShowPop}
            format={MARK_FORMAT.COLOR}
            brandColor={brandColor}
          />
          <HyperLinkButton showPop={showPop} setShowPop={setShowPop} pageList={pageList} />
        </div>
      </div>
    </Portal>
  );
};

export default HoveringToolbar;

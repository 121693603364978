import classnames from 'classnames/bind';
import { FC, Ref, useState } from 'react';
import { IFont } from '../../../../constants/font.constant';
import { ILNBType, ILNBTypes } from '../../../../interface/header/IMenu';
import { MARK_FORMAT, ShowPopType } from '../../textEditor.constant';
import { FontSizeType, FontType, IElementAlign } from '../../textEditor.type';
import style from '../SlateToolbar.module.scss';
import BlockButton from './BlockButton';
import ColorPickerButton from './ColorPickerButton';
import FontFamilyButton from './FontFamilyButton';
import FontSizeButton from './FontSizeButton';
import FontTypeButton from './FontTypeButton';
import HyperLinkButton from './HyperLinkButton';
import ImageUploadButton from './ImageUploadButton';
import MarkButton from './MarkButton';

const cx = classnames.bind(style);

interface IProps {
  showToolbarImageIcon?: boolean;
  toolbarClassName?: string;
  ref: Ref<HTMLDivElement>;
  fontType: FontType;
  setFontType: (v: FontType) => void;
  fontSize: FontSizeType;
  setFontSize: (v: FontSizeType) => void;
  pageList?: ILNBType<ILNBTypes>[];
  fontList: IFont[];
  defaultFont: IFont;
  brandColor?: string;
}

const SlateDefaultToolbar: FC<IProps & IElementAlign> = ({
  showToolbarImageIcon = true,
  fontSize,
  setFontSize,
  fontType,
  setFontType,
  setPlaceHolderAlign,
  pageList,
  fontList,
  defaultFont,
  brandColor,
}) => {
  const [showPop, setShowPop] = useState<ShowPopType>(null);

  return (
    <div className={cx('default-wrapper')}>
      <div className={cx('wrapper')}>
        <FontTypeButton
          fontSize={fontSize}
          setFontSize={setFontSize}
          fontType={fontType}
          setFontType={setFontType}
          showPop={showPop}
          setShowPop={setShowPop}
        />
      </div>
      <div className={cx('wrapper')}>
        <FontFamilyButton showPop={showPop} setShowPop={setShowPop} fontList={fontList} defaultFont={defaultFont} />
      </div>
      <div className={cx('wrapper')}>
        <FontSizeButton
          fontSize={fontSize}
          setFontSize={setFontSize}
          fontType={fontType}
          setFontType={setFontType}
          showPop={showPop}
          setShowPop={setShowPop}
        />
      </div>
      <div className={cx('wrapper')}>
        <BlockButton format={'left'} setPlaceHolderAlign={setPlaceHolderAlign} />
        <BlockButton format={'center'} setPlaceHolderAlign={setPlaceHolderAlign} />
        <BlockButton format={'right'} setPlaceHolderAlign={setPlaceHolderAlign} />
      </div>
      <div className={cx('wrapper')}>
        <MarkButton format={'bold'} />
        <MarkButton format={'italic'} />
        <MarkButton format={'underline'} />
        <MarkButton format={'strikethrough'} />
        <ColorPickerButton
          showPop={showPop}
          setShowPop={setShowPop}
          format={MARK_FORMAT.HIGHLIGHT}
          brandColor={brandColor}
        />
        <ColorPickerButton
          showPop={showPop}
          setShowPop={setShowPop}
          format={MARK_FORMAT.COLOR}
          brandColor={brandColor}
        />
        <HyperLinkButton showPop={showPop} setShowPop={setShowPop} pageList={pageList} />
        {showToolbarImageIcon && <ImageUploadButton format={'image'} />}
      </div>
    </div>
  );
};
export default SlateDefaultToolbar;

import _axios from 'axios';
import Cookies from 'js-cookie';
import { EnvType } from '../../constants/env.constant';
import CustomAxiosError from '../../interface/error/CustomAxiosError';
import { postLlmTokenIssueV1 } from '../../auth';

const LLM_BASE_URL = {
  production: 'https://api-llm.ats.kr-pr-midasin.com',
  staging: 'https://api-llm.ats.kr-st-midasin.com',
  staging2: 'https://api-llm.ats.kr-st2-midasin.com',
  development: 'https://api-llm.ats.kr-dv-midasin.com',
};

const getLlmBaseURL = () => {
  // eslint-disable-next-line turbo/no-undeclared-env-vars
  const env = process?.env?.NEXT_PUBLIC_ENV ?? 'development';
  // eslint-disable-next-line turbo/no-undeclared-env-vars

  return LLM_BASE_URL[env as EnvType];
};

export const generateAxios = () => {
  const axios = _axios.create({ baseURL: getLlmBaseURL() });
  axios.defaults.withCredentials = true;

  /** 모든 요청 때마다 header에 cookie에 있는 accessToken 담기 */
  axios.interceptors.request.use(function (config) {
    const accessToken = Cookies.get('llm_access_token');
    if (!accessToken) return config;

    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  });

  /** 요청의 응답이 인증 에러일 경우 accessToken 재발급, 원래 하려던 요청 새로 발급받은 accessToken으로 재요청 */
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      const {
        response: { data },
        config,
      } = error;

      if (data.status === 403) {
        const { accessToken } = await postLlmTokenIssueV1();
        Cookies.set('llm_access_token', accessToken, { expires: 365 });
        return axios(config);
      }

      return Promise.reject(new CustomAxiosError(data));
    }
  );

  return axios;
};

const axios = generateAxios();

export { axios };

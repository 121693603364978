import { Ref } from 'react';
import { IFont } from '../../constants/font.constant';
import { ILNBType, ILNBTypes, ISingleLNBType } from '../../interface/header/IMenu';
import { ShowPopType } from './textEditor.constant';

export type BlockFormatType =
  | 'paragraph'
  | 'bulleted-list'
  | 'numbered-list'
  | 'list-item'
  | 'left'
  | 'right'
  | 'center'
  | 'button'
  | 'image';

export type EditorAlign = 'left' | 'right' | 'center';
export type CustomElement = {
  type: BlockFormatType;
  align?: EditorAlign;
  subType?: string;
  children: CustomElement[] | CustomText[];
  label?: string;
  url?: string;
  imageUrl?: string;
  pageLink?: {
    type?: 'PAGE' | 'URL';
    lnb: ILNBType<ISingleLNBType>;
  };
  size?: 'S' | 'M' | 'L';
  style?: {
    backgroundColor?: string;
    color?: string;
    borderRadius?: number;
  };
  imageStyle?: {
    width?: number;
    height?: number | string;
    borderRadius: number;
    align: EditorAlign;
  };
  link?: string;
};

export const FONT_SIZE = [6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 32, 34, 38, 40, 48, 56, 64] as const;
export const TYPICAL_FONT_SIZE = [12, 14, 16, 20, 24, 32, 40, 48, 64] as const;

export type FontSizeType = (typeof FONT_SIZE)[number];
export type TypicalFontSizeType = (typeof TYPICAL_FONT_SIZE)[number];

export type CustomText = {
  text: string;
  bold?: boolean;
  highlight?: string;
  underline?: boolean;
  italic?: boolean;
  strikethrough?: boolean;
  link?: string;
  pageLink?: {
    type?: 'PAGE' | 'URL';
    lnb: ILNBType<ISingleLNBType>;
  };
  fontSize: FontSizeType;
  fontType: FontType;
  fontFamily?: string;
  color?: string;
};

export interface ISlateFont {
  fontSize: FontSizeType;
  setFontSize: (v: FontSizeType) => void;
  fontType: FontType;
  setFontType: (v: FontType) => void;
  showPop: ShowPopType;
  setShowPop: (v: ShowPopType) => void;
}

export type FontType =
  | 'title1'
  | 'title2'
  | 'title3'
  | 'title4'
  | 'title5'
  | 'content1'
  | 'content2'
  | 'content3'
  | 'content4';

//Toolbar
export type SlateToolbarType<T> = {
  type: 'solid' | 'float';
} & T;

export type SlateToolbarTypes = SolidToolbar | FloatToolbar;

export type SolidToolbar = {
  solid: {
    showImageIcon?: boolean;
    button: boolean;
  };
};

export type FloatToolbar = {
  float: {
    showImageIcon?: boolean;
  };
};

export interface ISlateSolidToolbarProps {
  backgroundColor: string;
  onChangeBgColor?: (backgroundColor: string) => void;
}

export interface IElementAlign {
  setPlaceHolderAlign?: (v: EditorAlign) => void;
}

export interface ISlateToolbarProps {
  toolbarClassName?: string;
  ref: Ref<HTMLDivElement>;
  fontSize: FontSizeType;
  setFontSize: (v: FontSizeType) => void;
  fontType: FontType;
  setFontType: (v: FontType) => void;
  toolbarOption?: SlateToolbarType<SlateToolbarTypes>;
  fontList: IFont[];
  defaultFont: IFont;
  pageList?: ILNBType<ILNBTypes>[];
  brandColor?: string;
}

export const PREVIEW_WITH_PREVENT_LINK = {
  POPUP: 'popup',
  POST: 'post',
};

export type PreviewWithPreventLinkType = (typeof PREVIEW_WITH_PREVENT_LINK)[keyof typeof PREVIEW_WITH_PREVENT_LINK];

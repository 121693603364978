import classNames from 'classnames/bind';
import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { BaseEditor, createEditor, Descendant, Node } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, ReactEditor, RenderLeafProps, Slate, withReact } from 'slate-react';
import { RenderElementProps } from 'slate-react/dist/components/editable';
import { baseFontList, DEFAULT_FONT, IFont } from '../../constants/font.constant';
import { LANGUAGE } from '../../constants/language';
import useWarningToast from '../../context/toast/useWarningToast';
import { useUrl } from '../../hook/useUrl';
import useUrlRouter from '../../hook/useUrlRouter';
import { HasOption, PageList } from '../../interface/common/hasOption';
import { ViewModeType } from '../../interface/common/ViewMode';
import { ILNBType, ILNBTypes, URL_TYPE } from '../../interface/header/IMenu';
import { convertFontSize, convertLineHeight } from '../../util/convertFontSize';
import { SOLUTION_TYPE } from '../../util/solution';
import HoveringToolbar from './HoveringToolbar/HoveringToolbar';
import styles from './SlateEditor.module.scss';
import ImageElement from './SlateImageElement/ImageElement';
import { BLOCK_BUTTON_SIZE, DEFAULT_FONT_SIZE, DEFAULT_FONT_TYPE } from './textEditor.constant';
import {
  CustomElement,
  CustomText,
  EditorAlign,
  FontSizeType,
  FontType,
  PREVIEW_WITH_PREVENT_LINK,
  SlateToolbarType,
  SlateToolbarTypes,
  SolidToolbar,
} from './textEditor.type';
import CustomButtonSettingToolbar from './toolbar/component/CustomButtonSettingToolbar';
import SlateToolbar from './toolbar/SlateToolbar';
import { useSlateKeydown } from './useSlateKeydown';

const cx = classNames.bind(styles);

declare module 'slate' {
  // FIXME ICustomTypes로 바꾸면 에러 나서 현 이름 유지
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

type IProps = {
  value: Descendant[];
  onChange: (value: Descendant[]) => void;
  placeholder?: string;
  toolbarClassName?: string;
  editorClassName?: string;
  innerClassName?: string;
  width?: string; //TODO: 주입받는 style 도 늘면 Props로 바꾸기
  fullWidth?: boolean;
  disabled?: boolean;
  viewMode?: ViewModeType;
  backgroundColor?: string;
  showToolbar?: boolean;
  toolbarOption?: SlateToolbarType<SlateToolbarTypes>;
  fontList?: IFont[];
  defaultFont?: IFont;
  pageChangeFont?: IFont;
  onChangeBgColor?: (backgroundColor: string) => void; //추후에 style Props로 확장
  pageList?: ILNBType<ILNBTypes>[];
  isPreview?: boolean;
  isEdit?: boolean;
  hoveringToolbar?: boolean;
  brandColor?: string;
  textColor?: string;
};

const withImages = (editor: ReactEditor) => {
  const { isVoid, insertData } = editor;

  editor.isVoid = (element) => {
    return element.type === 'image' ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    insertData(data);
  };

  return editor;
};

const SlateEditor: FC<IProps & HasOption> = ({
  value,
  onChange,
  placeholder,
  toolbarClassName,
  editorClassName,
  innerClassName,
  width,
  fullWidth,
  disabled,
  viewMode = 'PC',
  showToolbar = false,
  backgroundColor = 'transparent',
  toolbarOption = { type: 'float', float: {}, solid: { button: false } },
  fontList = baseFontList,
  defaultFont = DEFAULT_FONT,
  onChangeBgColor,
  pageList = [],
  isPreview = false,
  isEdit = false,
  hoveringToolbar = false,
  options,
  brandColor,
  textColor,
}) => {
  const [editor] = useState(() => withImages(withReact(withHistory(createEditor()))));
  const editorToolbarRef = useRef<HTMLDivElement>(null);
  const editorContainerRef = useRef<HTMLDivElement>(null);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [fontType, setFontType] = useState<FontType>(DEFAULT_FONT_TYPE);
  const [placeHolderAlign, setPlaceHolderAlign] = useState<EditorAlign>('center');
  const [activeButtonSetting, setActiveButtonSetting] = useState(false);
  const handleKeydown = useSlateKeydown({ editor, fontSize, setFontSize, fontType });

  const { type: toolbarType } = toolbarOption;
  const { button } = (toolbarOption as SlateToolbarType<SolidToolbar>).solid ?? { button: false };
  const renderElement = useCallback(
    (props: RenderElementProps) => (
      <Element
        {...props}
        readOnly={disabled}
        isPreview={isPreview}
        isEdit={isEdit}
        pageList={pageList}
        options={options}
      />
    ),
    [disabled, options]
  );
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => (
      <Leaf
        {...props}
        viewMode={viewMode}
        readOnly={disabled}
        isPreview={isPreview}
        isEdit={isEdit}
        options={options}
        textColor={textColor}
        fontList={fontList}
      />
    ),
    [disabled, viewMode, options]
  );

  useEffect(() => {
    const element = Node.ancestor(editor, [0]) as CustomElement;
    const leaf = Node.get(editor, [0, 0]) as CustomText;

    if (leaf.text === '') {
      setFontSize(leaf.fontSize ?? DEFAULT_FONT_SIZE);
      setFontType(leaf.fontType ?? DEFAULT_FONT_TYPE);
      setPlaceHolderAlign(element.align ?? 'center');
    }
  }, []);

  return (
    <div
      className={cx('container', {
        'full-width': fullWidth,
      })}
      ref={editorToolbarRef}
    >
      <Slate editor={editor} value={value} onChange={(newValue) => onChange(newValue)}>
        {hoveringToolbar && (
          <HoveringToolbar
            setPlaceHolderAlign={setPlaceHolderAlign}
            pageList={pageList}
            fontSize={fontSize}
            setFontSize={setFontSize}
            fontType={fontType}
            setFontType={setFontType}
            fontList={fontList}
            defaultFont={defaultFont}
            brandColor={brandColor}
          />
        )}
        {!disabled && showToolbar && (
          <SlateToolbar
            toolbarClassName={toolbarClassName}
            ref={editorToolbarRef}
            fontSize={fontSize}
            setFontSize={setFontSize}
            fontType={fontType}
            setFontType={setFontType}
            setPlaceHolderAlign={setPlaceHolderAlign}
            fontList={fontList}
            defaultFont={defaultFont}
            toolbarOption={toolbarOption}
            backgroundColor={backgroundColor}
            onChangeBgColor={onChangeBgColor}
            pageList={pageList}
            brandColor={brandColor}
          />
        )}
        <div
          data-id={'slate-editor'}
          style={{ width, backgroundColor: backgroundColor }}
          className={cx(editorClassName && editorClassName)}
          ref={editorContainerRef}
        >
          <Editable
            renderPlaceholder={({ attributes }) => (
              <span
                {...attributes}
                className={cx('placeholder')}
                style={{ ...attributes.style, fontSize: `${fontSize}px`, textAlign: placeHolderAlign }}
              >
                {placeholder}
              </span>
            )}
            placeholder={placeholder}
            className={cx('editor', innerClassName && innerClassName)}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            readOnly={disabled}
            disabled={disabled}
            style={{
              justifyContent: 'left',
            }}
            onKeyDown={handleKeydown}
          />
        </div>
        {showToolbar && toolbarType === 'solid' && button && (
          <CustomButtonSettingToolbar setActiveButtonSetting={setActiveButtonSetting} />
        )}
      </Slate>
    </div>
  );
};

const Element = (
  props: RenderElementProps & {
    readOnly?: boolean;
    isPreview?: boolean;
    isEdit?: boolean;
    pageList: ILNBType<ILNBTypes>[];
  } & HasOption
) => {
  const { attributes, children, element, readOnly, isPreview, pageList, options } = props;
  const style = { textAlign: element.align };
  const { setPreviewDisabledWarningToast } = useWarningToast();
  const searchParams = useSearchParams() as ReadonlyURLSearchParams;

  switch (element.type) {
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          <div>{children}</div>
        </li>
      );
    case 'button':
      return (
        <div {...attributes} style={{ textAlign: element.align }}>
          <button
            onClick={(e) => {
              if (!readOnly) {
                e.preventDefault();
                return;
              }
              if (Object.values(PREVIEW_WITH_PREVENT_LINK).includes(searchParams.get('previewType') ?? '')) {
                return setPreviewDisabledWarningToast();
              }
              window.open(element.pageLink?.lnb.page.url ?? element.url ?? '', '_blank');
            }}
            style={{
              margin: '24px auto',
              backgroundColor: element.style!.backgroundColor,
              borderRadius: element.style!.borderRadius,
            }}
          >
            <span
              style={{
                display: 'block',
                color: `${element.style!.color}`,
                padding: BLOCK_BUTTON_SIZE[element.size ?? 'S'],
              }}
            >
              {children}
            </span>
          </button>
        </div>
      );
    case 'image':
      return (
        <ImageElement {...props} isPreview={isPreview} readOnly={readOnly} pageList={pageList} options={options} />
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({
  attributes,
  children,
  leaf,
  viewMode,
  readOnly,
  isPreview,
  isEdit,
  options,
  textColor,
  fontList,
}: RenderLeafProps & {
  viewMode?: ViewModeType;
  readOnly?: boolean;
  isPreview?: boolean;
  isEdit?: boolean;
  textColor?: string;
  fontList?: IFont[];
} & HasOption) => {
  const { setPreviewDisabledWarningToast } = useWarningToast();
  const { handleUrlClick } = useUrlRouter();
  const { buildUrl, getSearchParam } = useUrl();
  const languageType = options && options['languageType'];

  const isValidFont = (fontList ?? []).map((font) => font.fontFamily).includes(leaf.fontFamily ?? '');
  const fontFamilyOption = isValidFont
    ? {
        fontFamily: leaf.fontFamily,
      }
    : {};

  const style = Object.assign(
    leaf.fontSize
      ? {
          fontSize: `${convertFontSize(leaf.fontSize, viewMode)}px`,
          lineHeight: `${convertLineHeight(convertFontSize(leaf.fontSize, viewMode)) ?? 22}px`,
        }
      : { fontSize: '14px', lineHeight: '22px' },
    fontFamilyOption,
    { color: leaf.color ? leaf.color : '' }
  );

  if (leaf.bold) {
    children = <b style={{ ...style }}>{children}</b>;
  }

  if (leaf.highlight && leaf.text) {
    children = (
      <span
        className={cx('highlight')}
        style={{ ...style, backgroundColor: leaf.highlight, padding: '0 6px', borderRadius: '4px' }}
      >
        {children}
      </span>
    );
  }

  if (leaf.underline) {
    children = <u style={style}>{children}</u>;
  }

  if (leaf.strikethrough) {
    children = <s style={style}>{children}</s>;
  }

  if (leaf.italic) {
    children = (
      <i className={cx('italic')} style={style}>
        {children}
      </i>
    );
  }
  if (leaf.pageLink) {
    children = (
      <a
        className={cx('link')}
        style={{ fontSize: style.fontSize }}
        onClick={(e) => {
          if (isEdit || !readOnly) return e.preventDefault();

          if (Object.values(PREVIEW_WITH_PREVENT_LINK).includes(getSearchParam('previewType') ?? '')) {
            return setPreviewDisabledWarningToast();
          }

          const isExternal = leaf.pageLink?.lnb?.page.urlType === URL_TYPE.EXTERNAL || leaf.pageLink?.type === 'URL';

          const { url, pageSn, urlType } = leaf.pageLink?.lnb.page!;
          if (isPreview) {
            return handleUrlClick({
              url: isExternal
                ? url
                : buildUrl('/editor/preview')
                    .withSearchParam('designSn', getSearchParam('designSn'))
                    .withSearchParam('pageSn', pageSn)
                    .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
                    .getResult(),
              isExternal,
              isPreview,
            });
          }

          const pageLinkList = options?.pageLinkList as PageList;
          const internalUrl = pageLinkList?.find((page) => page.pageSn === pageSn)?.urlPath;
          const HOST_NAME = {
            JOBDA: `${location.origin}/`,
            JOBFLEX: `${location.origin}/career/`,
          };

          let finalUrl = url;
          if (urlType === 'INTERNAL') {
            finalUrl = buildUrl(`${HOST_NAME[SOLUTION_TYPE]}/${internalUrl}`)
              .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
              .getResult();
          }
          window.open(finalUrl);
        }}
      >
        {children}
      </a>
    );
  }

  return (
    <span {...attributes} style={style}>
      {children}
    </span>
  );
};

export default SlateEditor;
